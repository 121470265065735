import React from 'react';
import config from '../../config';
import fredrikBILD1 from '../assets/images/5R9A5107 Fredrik Björnerud.jpg';

export default function Subscribe() {
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    window.location.reload();
  };
  const imageStyle = {
    borderRadius: '100%',
  };
  return (
    <section id="signup" className="signup-section">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8 mx-auto text-center">
            <div className="d-flex align-items-center justify-content-center mb-5">
              <h2 className="text-white ml-3">Vill du bli kontaktad?</h2>
            </div>
            <form
              className="form-inline d-flex"
              name="contact"
              method="POST"
              data-netlify="true"
            >
              <input type="hidden" name="form-name" value="contact" />

              <input
                name="email"
                type="email"
                className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0 same-height"
                id="inputEmail"
                placeholder="Email address..."
                required
              />
              <div className="input-group-prepend"></div>
              <input
                className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0 same-height"
                id="message"
                name="message"
                placeholder="Meddelande"
                required
              />

              <button type="submit" className="btn btn-primary mx-auto">
                Skicka
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
