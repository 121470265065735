import React from 'react';
import test4 from '../assets/images/photo-1518186285589-2f7649de83e0.avif';

export default function Footer() {
  return (
    
    <footer className="bg-black small text-center text-white-50">
      
      
      <div className="container">
        

      Copyright &copy; Björnerud 2023
      </div>

    </footer>
  );
}
