import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';

import '/ModernProjects.css';
import test3 from '../assets/images/photo-1553877522-43269d4ea984.jpeg';
import test4 from '../assets/images/photo-1518186285589-2f7649de83e0.png';

import fredrikBILD1 from '../assets/images/5R9A5073-2 Fredrik Björnerud.jpg';
import fredrikBILD2 from '../assets/images/5R9A5143 fix Fredrik Björnerud.jpg';

import background1 from '../assets/images/bild1test-d21b0c51a129fc4207c1542887358a93.png';
import background2 from '../assets/images/bild2test-42d880765a3d9b4b54298e475e8ca1b0.png';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase heading2">
            {config.heading}
          </h1>
          <h2 className="text-white-50 mx-auto mt-2 mb-5">
            {config.subHeading}
          </h2>
        </div>
      </div>
    </header>

    <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="text-white mb-4">
              Optimala resultat med rätt förutsättningar
            </h2>
            <p className="text-white-50">
              Lyckade bostadsutvecklingsprojekt innehåller många olika
              framgångsfaktorer från markförvärv fram till försäljning och
              eftermarknad. Konsten att skapa en målgruppsanpassad produkt
              kräver rätt underlag och rätt analyser med start i ett väldigt
              tidigt skede och sedan genom hela affären. Nyckeln heter
              kundfokus.
              <br></br>
              <br></br>
              Hur vill kunden bo? Vad ger mervärde och vilket är marknadens
              nästa behov, det som för projektet ska bli den stora
              konkurrensfördelen? Vilka och hur stora värden bygger du in i ditt
              projekt? Det handlar om optimalt nyttjade kvadrat; att hitta rätt
              målgrupp och rätt produkt för just det läget. Det handlar om hur
              du får den mest optimala avkastningen på ditt kapital, helt
              enkelt.
              <Scroll type="id" element="projects">
                <a href="#projects" className="ye">
                  <div>Om mig!</div>
                </a>
              </Scroll>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="projects" className="projects-section bg-light">
      <div className="container">
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="col-xl-6 col-lg-7">
            <img
              className="img-fluid mb-3 mb-lg-0"
              src={fredrikBILD1}
              loading="lazy"
              alt=""
            />
          </div>
          <div className="col-xl-5 col-lg-5">
            <div className="featured-text text-center text-lg-left">
              <h4>Om mig</h4>
              <p className="text-black-50 mb-0">
                För att hitta den rätta, målgruppsanpassade produkten fungerar
                jag som din sparringpartner och utmanar marknaden – hur får vi
                bäst avkastning på projektet?
                <br></br>
                <br></br>
                Jag är gärna med redan vid förvärv för en komplett analys
                utifrån intäkt, konkurrens, målgrupp. Jag tillför ett
                utifrån-och-in-perspektiv genom hela resan då jag enkelt byter
                mellan utvecklarens, marknadens och kundens glasögon.
                <br></br>
                <br></br>
                Med över 20 års erfarenhet som specialist inom nyproduktion i
                olika roller och i olika konjunkturer rör jag mig naturligt
                mellan de olika perspektiven vilket ger dig en trygghet att
                kunna fokusera på din affär. Med en gedigen erfarenhet och
                förståelse för processen att utveckla ett bostadsprojekt med
                både kundperspektiv och försäljningsfokus fungerar jag som
                länken mellan bostadsutvecklare, arkitekt, marknad och mäklare.
                <br></br>
                <br></br>
                Använd mig genom hela processen eller i de delar där du inte har
                intern kunskap att tillgå. Tillsammans skapar vi lösningar som
                leder till optimala resultat för dina bostadsprojekt.
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-6">
            <img
              className="img-fluid"
              src={background1}
              loading="lazy"
              alt=""
            />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <h4 className="text-white">
                    Rätt underlag ger rätt produkt:
                  </h4>
                  <p className="mb-0 text-white-50">
                    Analyserade beslutsunderlag inför förvärv, säljstart eller
                    vid pågående försäljning. Workshops för projektets ”why?”.
                    Målgruppsarbete, konceptframtagning, lägenhetsfördelning &
                    utformning, kvaliteter med mera.
                  </p>
                  <hr className="d-none d-lg-block mb-0 ml-0" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters">
          <div className="col-lg-6">
            <img
              className="img-fluid"
              src={background2}
              loading="lazy"
              alt=""
            />
          </div>
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-right">
                  <h4 className="text-white">
                    Rätt strategi ger rätt försäljning:
                  </h4>
                  <p className="mb-0 text-white-50">
                    Försäljningsstrategier, marknadsstrategier, prisoptimering,
                    intressentbearbetning, upphandling reklambyrå &
                    fastighetsmäklare samt kontaktperson under hela
                    försäljningsprocessen, kundvård & kundevent med mera.
                  </p>
                  <hr className="d-none d-lg-block mb-0 mr-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Subscribe />

    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
